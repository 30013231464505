import React, {useState} from "react";
import {Box, Container, Grid, IconButton, Link, Stack, Typography} from "@mui/material";
import {AiOutlineSlack, AiOutlineTwitter} from "react-icons/all";
import {IconContext} from "react-icons";

function Footer() {
  const [color , setColor] = useState('');
  const font = ["Montserrat", "Helvetica Neue", 'Arial', 'sans-serif'].join(',');
  const prim = 'rgba(47, 79, 79,0.92)';
  const second = 'rgba(255, 255, 255,0.9)';
  const hover = 'rgba(240, 240, 255,0.9)';

  return (
      <Box sx={{flexGrow:1, position:'absolute', backgroundColor:'none', width:'100%', bottom:0, height: '5vh'}}>
        <Stack className='stack' sx={{flexDirection:'row'}} container>
          <Grid item container sx={{float:'left', ml:'5%'}}md={5}>
            <Grid item>
              <Link href="/" underline="none">
                <Typography color='white' sx={{fontSize: 20, fontFamily: font, fontWeight:10}}>
                  Privacy
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <Link href="/" underline="none" >
                <Typography className='contact' color='white' sx={{fontSize: 20, fontFamily: font, fontWeight:10,pl:5}}>
                  Contact
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid className="social" item container sx={{float:'right', ml:'55%'}} md={5}>
            <Grid item>
              <Typography color='white' sx={{fontSize: 20, fontFamily: font, fontWeight:10, mr:1}}>
                Follow Us:
              </Typography>
            </Grid>
            <Grid item mb={1} md={2}>
              <IconContext.Provider value={{ size:'30', color: "white"}}>
                <a href='https://www.slack.com/' target='_blank'>
                  <div>
                    <AiOutlineSlack/>
                  </div>
                </a>
              </IconContext.Provider>
            </Grid>
            <Grid item>
              <IconContext.Provider href='www.twitter.com' target='_blank' value={{ size:'30', color: "white"}}>
                <div href='https://www.twitter.com/'>
                  <AiOutlineTwitter/>
                </div>
              </IconContext.Provider>
            </Grid>
          </Grid>
        </Stack>
      </Box>
  )
}
export default Footer;
