import { initializeApp } from 'firebase/app';
import {getDatabase, ref, set, onValue, push, child, update} from "firebase/database";
import { getAuth } from "firebase/auth";

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(config, 'Excap-development');

function init_database() {
    return getDatabase(app);
}
function auth_token() {
    return getAuth(app);
}
function isAdmin(user) {
    if(user){
        return user.uid == process.env.REACT_APP_ADMIN;
    }
    return false;
}

function Fire () {
    const database = init_database();
    const api =
        {
            writeUserData: function (userId, firstName, lastName, email, password) {
                set(ref(database, 'users/' + userId), {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    password: password,
                    status: false
                })
                    .then(r => {

                    })
                    .catch((e) => {
                    })
            } ,
            getUsers: function (tab, callback) {
                onValue(ref(database, 'users/'), (snapshot) => {
                    if (snapshot.val()) {
                        const userlist = [];
                        const pendingList = [];
                        Object.entries(snapshot.val()).forEach(elem => {
                            elem[1].status ? userlist.push(elem) : pendingList.push(elem)});
                        tab ? callback(pendingList) : callback(userlist);
                    }
                }, {
                    onlyOnce: true
                });
            },
            writeHolding: function (stockList, equity, invested, user) {





                // // Get a key for a new Post.
                // const newPostKey = push(child(ref(init_database()), 'posts')).key;
                //
                // // Write the new post's data simultaneously in the posts list and the user's post list.
                // const updates = {};
                // updates['/holdings/' + newPostKey] = x;
                //
                // return update(ref(init_database()), updates);
            }
        };
    return api;
}


export {init_database,auth_token, isAdmin};

export default Fire;