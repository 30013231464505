import React from "react";
import {Box, Card, CardContent, CardMedia, Grid, Paper, Typography} from "@mui/material";
import CustomCard from "../../assets/reused/customcard";
import Footer from "../../components/Footer";

function Team() {
    const font = ["Montserrat", "Helvetica Neue", 'Arial', 'sans-serif'].join(',');
    const toolbarColor = 'rgba(27, 35, 48, 0.95)';

  return (
      <Box id='team' sx={{height:'100vh'}}>
          <div className="space" style={{height:'15vh'}}></div>
          <Typography className='teamTitle' color='white' sx={{textAlign:'center',fontSize: 50, fontFamily: font, fontWeight:10}}>
              Managing Partner
          </Typography>
          <Card className='profileCard' sx={{ mt: 3, display: 'flex', height:'45vh', bgcolor:toolbarColor, mx:3}}>
              <Box className='profileBox'sx={{ display: 'flex', flexDirection: 'row' }}>
                  <CardMedia position={'relative'} sx={{ml:2,mt:2}}>
                      <CustomCard/>
                  </CardMedia>
                  <CardContent sx={{flex: '1 0 auto' }}>
                      <Paper sx={{bgcolor:'transparent', boxShadow:'none'}}>
                          <Typography paragraph={true} className='profileText' color='white' sx={{ml:5, mr:2, pt:'8vh', width:'65vw', height:'30vh', textAlign:'center',fontSize: 14, fontFamily: font, fontWeight:10}}>
                              Carter Jones is the founder and managing partner of Jones Digital Capital Fund. Carter is an accomplished digital asset investor who brings his cryptocurrency knowledge and experience to this fund. He has a proven record of delivering strong returns in the cryptocurrency space and seeks to help his clients achieve similar results by diversifying into this new asset class. Prior to Jones Digital Capital Fund, Carter was the founder and manager of J&J Investments, a small self-directed cryptocurrency partnership, where he directly conducted in-depth analysis and research that resulted in positive investment decisions. Before that, Carter was the COO of TorenTech, a start-up entity in Houston, TX, whose goal was to create a cryptocurrency based internal economy in the health and elite fitness world, working with performance trainers and professional athletes to monetize valuable health metrics. Carter is a licensed investment advisor under FINRA, and holds Series 7 and 66 licenses, as well as Minnesota State Life and Health Insurance certifications.
                          </Typography>
                      </Paper>
                  </CardContent>
              </Box>
          </Card>
          <Footer />
      </Box>
  );
}
export default Team;
