import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import IndexNavbar from "../../components/IndexNavbar";
import Footer from "../../components/Footer";
import {Box, Grid, Paper, TextField, Typography, Button, Alert, Input} from "@mui/material";
import {GiPadlock} from "react-icons/all";
import {IconContext} from "react-icons";

import Fire, {auth_token} from "../../server/firebase";
import {createUserWithEmailAndPassword} from "firebase/auth";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

function SignUp() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);


    const primary = 'rgba(47, 79, 79,0.7)';
    const font = ["Montserrat", "Helvetica Neue", 'Arial', 'sans-serif'].join(',');
    const FireApi = Fire();

    async function handleSubmit(e) {
        window.href='/dashboard';
        e.preventDefault();
        // Validation checks
        if (password !== confirmPassword) {
            return setErrorMessage('Passwords Must Match');
        }
        try {
            //await register(email, password);
        } catch (e) {
            setErrorMessage('Failed to Create Account');
        }
        setLoading(false);

        createUserWithEmailAndPassword(auth_token(), email, password)
            .then((userCredential) => {
                // Signed in
                setErrorMessage('');
                const user = userCredential.user ;
                FireApi.writeUserData(user.uid,firstName,lastName,email,password);
            })
            .catch((error) => {
                setErrorMessage(error.message);
            });
        setOpen(true);

    }
    // //DOCUSIGN
    // const key = '5a73ac93-e34e-4e6b-85e3-09856233a898';
    // useEffect(() => {
    //     fetch('https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature&client_id='+key+'&redirect_uri=https://localhost:3000/auth/docusign/callback')
    //         .then(response => response.json())
    //         .then(data => console.log(data))
    //         .catch(e => console.log(e));
    // },[])

    //fetch('https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature&client_id=27d086c1-c91b-43be-adbe-460cf46409b1&redirect_uri=https://localhost:3000/legal')
    //     .then(response => response.json())
    //     .then(data => console.log(data));



    //Oauth.generateAccessToken();
    //27d086c1-c91b-43be-adbe-460cf46409b1
    //239ec403-c621-4376-bad0-12ba13571f8e


    function handleInput(event) {
        if (event.currentTarget.id == 'firstName') {
            setFirstName(event.currentTarget.value);
        } else if (event.currentTarget.id == 'lastName') {
            setLastName(event.currentTarget.value);
        } else if (event.currentTarget.id == 'email') {
            setEmail(event.currentTarget.value);
        } else if (event.currentTarget.id == 'password') {
            setPassword(event.currentTarget.value);
        } else if (event.currentTarget.id == 'confirmPassword') {
            setConfirmPassword(event.currentTarget.value);
        }
    }

    function AlertDialog(props) {

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Thank you for your interest"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                           This part of the site is currently under development. Please stay tuned!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }


    return (
        <>
            {open && <AlertDialog/>}
            <IndexNavbar/>
            <div className="gtfo clear-filter" filter-color="blue">
                <div className="signup-background" style={{height: '100vh', width: '100vw'}}>
                    <div className="signup" style={{display: 'flex'}}>
                        <Grid container
                              component='main'
                              sx={{ mt: '10vh', mb: '10vh'}}
                              justifyContent="center"
                        >
                            <Grid item xs={8} sm={8} md={4} component={Paper} elevation={6} square sx={{backgroundColor:'rgba(255,255,255,.6)'}}>
                                <Box
                                    sx={{
                                        pt: 4,
                                        pb: 3,
                                        mx: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconContext.Provider value={{size: '25', color: "black"}}>
                                        <div>
                                            <GiPadlock/>
                                        </div>
                                    </IconContext.Provider>
                                    <Typography sx={{fontSize: 25, color: 'black', fontFamily: font}}>
                                        Sign Up
                                    </Typography>
                                    <Box>
                                        <Box display='block'>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            onChange={handleInput}
                                            sx={{bgcolor:'rgba(255,255,255,.8)', borderRadius:'7px'}}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            onChange={handleInput}
                                            sx={{bgcolor:'rgba(255,255,255,.8)', borderRadius:'7px'}}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email"
                                            onChange={handleInput}
                                            sx={{bgcolor:'rgba(255,255,255,.8)', borderRadius:'7px'}}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="password"
                                            type={'password'}
                                            label="Password"
                                            onChange={handleInput}
                                            sx={{bgcolor:'rgba(255,255,255,.8)', borderRadius:'7px'}}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            type={'password'}
                                            id="confirmPassword"
                                            label="Confirm Password"
                                            onChange={handleInput}
                                            sx={{bgcolor:'rgba(255,255,255,.8)', borderRadius:'7px'}}
                                        />
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            size="lg"
                                            onClick={handleSubmit}
                                            sx={{
                                                background: primary,
                                                my: 1,
                                                py:1,
                                                '&:hover': {background: primary, color: 'white' ,fontFamily:font, fontWeight:10}
                                            }}
                                        >
                                            Sign Up
                                        </Button>
                                        <Grid container>
                                            <Grid item xs>
                                                <Link href="#" variant="body2" sx={{textDecoration:'none'}}>
                                                    {"Forgot password?"}
                                                </Link>
                                            </Grid>
                                            <Grid item>
                                                <Link href="#" variant="body2" sx={{textDecoration:'none'}}>
                                                    {"Login"}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Footer/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignUp;

