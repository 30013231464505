import IndexNavbar from "../../components/IndexNavbar";
import Footer from "../../components/Footer";
import React, {useState} from "react";
import LoginPage from "./LoginPage";
import {Box, Button, Grid, Paper, TextField, Typography} from "@mui/material";
import {IconContext} from "react-icons";
import {GiPadlock} from "react-icons/all";
import {Link} from "react-router-dom";
import {signInWithEmailAndPassword} from "firebase/auth";
import Fire, {init_database, auth_token} from "../../server/firebase";
import {Redirect} from "react-router";

function Login () {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [redirect, setRedirect] = useState(<></>);

    const primary = 'rgba(47, 79, 79,0.7)';
    const font = ["Montserrat", "Helvetica Neue", 'Arial', 'sans-serif'].join(',');

    function handleSubmit(e) {
        e.preventDefault();
        signInWithEmailAndPassword(auth_token(), email, password)
            .then((userCredential) => {
                // Signed in
                setErrorMessage('');
                const user = userCredential.user;
                if (user.uid == process.env.REACT_APP_ADMIN) {
                    setRedirect(<Redirect to='/admin'/>);
                } else {
                    //setRedirect(<Redirect to='/dashboard'/>);
                }
            })
            .catch((error) => {
                setErrorMessage(error.message);
            });
    }
    function handleInput(event) {
        if (event.currentTarget.id == 'email') {
            setEmail(event.currentTarget.value);
        } else if (event.currentTarget.id == 'password') {
            setPassword(event.currentTarget.value);
        }
    }
    return (
        <>
            {redirect}
            <IndexNavbar/>
            <div className="gtfo clear-filter" filter-color="blue">
                <div className="signup-background" style={{height: '100vh', width: '100vw'}}>
                    <div className="login" style={{display: 'flex'}}>
                        <Grid container
                              component='main'
                              sx={{ mt: '20vh', mb: '10vh'}}
                              justifyContent="center"
                        >
                            <Grid item xs={8} sm={8} md={4} component={Paper} elevation={6} square sx={{backgroundColor:'rgba(255,255,255,.6)'}}>
                                <Box
                                    sx={{
                                        pt: 4,
                                        pb: 3,
                                        mx: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconContext.Provider value={{size: '25', color: "black"}}>
                                        <div>
                                            <GiPadlock/>
                                        </div>
                                    </IconContext.Provider>
                                    <Typography sx={{fontSize: 25, color: 'black', fontFamily: font}}>
                                        Login
                                    </Typography>
                                    <Box>
                                        <Box display='block'>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email"
                                                onChange={handleInput}
                                                sx={{bgcolor:'rgba(255,255,255,.8)', borderRadius:'7px'}}
                                            />
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="password"
                                                type={'password'}
                                                label="Password"
                                                onChange={handleInput}
                                                sx={{bgcolor:'rgba(255,255,255,.8)', borderRadius:'7px'}}
                                            />
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                size="lg"
                                                onClick={handleSubmit}
                                                sx={{
                                                    background: primary,
                                                    my: 1,
                                                    py:1,
                                                    '&:hover': {background: primary, color: 'white' ,fontFamily:font, fontWeight:10}
                                                }}
                                            >
                                                Sign Up
                                            </Button>
                                            <Grid container>
                                                <Grid item xs>
                                                    <Link href="#" variant="body2" sx={{textDecoration:'none'}}>
                                                        {"Forgot password?"}
                                                    </Link>
                                                </Grid>
                                                <Grid item>
                                                    <Link href="/signup" variant="body2" sx={{textDecoration:'none'}}>
                                                        {"Create Account"}
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Footer/>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Login;
