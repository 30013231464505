import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
} from "@mui/material";

import {useTheme} from "@mui/material/styles";
import {BsChevronDown, BsChevronUp} from "react-icons/all";
import {IconContext} from "react-icons";
import Fire from "../../server/firebase";
import CollapseRow from "./components/CollapseRow";
import Button from "@mui/material/Button";


function Investors(props) {
    const [user, setUser] = React.useState(null);
    const [userData,setUserData] = useState([]);

    const fire = Fire();
    const font = ["Montserrat", "Helvetica Neue", 'Arial', 'sans-serif'].join(',');
    const prim = 'rgba(47, 79, 79,0.92)';
    const secondaryColor = 'rgba(255, 255, 255,0.9)';
    const hover = 'rgba(240, 240, 255,0.9)';
    const theme = useTheme();
    theme.direction = 'lft';

    useEffect(() => {
        fire.getUsers(props.tab, setUserData);
    }, []);


    const columns = [
        {id: 'user', label: 'Investor', minWidth: 170},
        {id: 'joined', label: 'Joined', minWidth: 100},
        {
            id: 'invested',
            label: 'Invested',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'equity',
            label: 'Equity',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'returns',
            label: 'Returns',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
    ];

    function createData(lastName, firstName, joined, invested, equity) {
        const name = lastName + ", " + firstName;
        const returns = equity - invested;
        return {name, joined, invested, equity, returns};
    }

    function handleUser(user) {
        props.callback(user)
    }

    return (
        <div>
            <TableContainer sx={{height: 440}}>
                <Table sx={{width:'100%'}} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor:prim}}/>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    style={{minWidth: 100, backgroundColor:prim, color: secondaryColor }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userData
                            .map((user,key) => {
                                const visibleData = createData(user[1].lastName, user[1].firstName, 'Date', 111110, 100000);
                                    return (
                                        <>
                                            <TableRow component={'div'} sx={{'& > *': {borderBottom: 'unset'}}} onClick={() => handleUser(user)}>
                                                <TableCell>
                                                    {visibleData.name}
                                                </TableCell>
                                                <TableCell>
                                                    {visibleData.joined}
                                                </TableCell>
                                                <TableCell>
                                                    {visibleData.invested}
                                                </TableCell>
                                                <TableCell>
                                                    {visibleData.equity}
                                                </TableCell>
                                                <TableCell>
                                                    { visibleData.returns }
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )
                                }
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
export default Investors;