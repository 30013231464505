import React from "react";

import './App.css';
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import Landing from "./pages/Landing/Landing";
import {Redirect} from "react-router";
import Login from "./pages/Authentication/Login";
import SignUp from "./pages/Authentication/SignUp";
import Dashboard from "./pages/Dashboard/Dashboard";
import Admin from "./pages/Admin/Admin";
import Portfolio from "./pages/Dashboard/Portfolio";
import Fire, {auth_token, isAdmin} from './server/firebase';


function App() {
    var user = auth_token().currentUser;

  return (
      <BrowserRouter>
          <Switch>
              <Route path="/home" render={(props) => <Landing {...props} />} />
              <Route
                  path="/login"
                  render={(props) => <Login {...props} />}
              />
              <Route
                  path="/signup"
                  render={(props) => <SignUp {...props} />}
              />
              <Route
                  path="/portfolio"
                  render={(props) => <Portfolio {...props} />}
              />
              {user && <Route
                  path="/dashboard"
                  render={(props) => <Dashboard {...props} />}
              />}
              {isAdmin(user) && <Route
                  path="/admin"
                  render={(props) => <Admin {...props} />}
              />}

              <Redirect to="/home" />
              <Redirect from="/" to="/home" />
          </Switch>
      </BrowserRouter>
  );
}

export default App;
