import './customcard.css'
import {Typography} from "@mui/material";
import React from "react";
function CustomCard() {
    const profileImage = "url(" + require("../img/carter-prof.jpg").default + ")";
    const font = ["Montserrat", "Helvetica Neue", 'Arial', 'sans-serif'].join(',');

    return (
        <>
        <div className="background" style={{bgcolor:'green'}}></div>
        <div id="curve" className="card" style={{backgroundImage:profileImage}}>
            <div className="footerCard">
                <div className="connections">
                    <div className="connection facebook">
                        <div className="icon"></div>
                    </div>
                    <div className="connection twitter">
                        <div className="icon"></div>
                    </div>
                    <div className="connection behance">
                        <div className="icon"></div>
                    </div>
                </div>
                <svg id="curve">
                    <path id="p" d="M0,200 Q80,100 400,200 V150 H0 V50" transform="translate(0 300)"/>
                    <rect id="dummyRect" x="0" y="0" height="450" width="400" fill="transparent"/>
                    <animate href="#p" attributeName="d" to="M0,50 Q80,100 400,50 V150 H0 V50" fill="freeze"
                             begin="dummyRect.mouseover" end="dummyRect.mouseout" dur="0.1s" id="bounce1"/>
                    <animate href="#p" attributeName="d" to="M0,50 Q80,0 400,50 V150 H0 V50" fill="freeze"
                             begin="bounce1.end" end="dummyRect.mouseout" dur="0.15s" id="bounce2"/>
                    <animate href="#p" attributeName="d" to="M0,50 Q80,80 400,50 V150 H0 V50" fill="freeze"
                             begin="bounce2.end" end="dummyRect.mouseout" dur="0.15s" id="bounce3"/>
                    <animate href="#p" attributeName="d" to="M0,50 Q80,45 400,50 V150 H0 V50" fill="freeze"
                             begin="bounce3.end" end="dummyRect.mouseout" dur="0.1s" id="bounce4"/>
                    <animate href="#p" attributeName="d" to="M0,50 Q80,50 400,50 V150 H0 V50" fill="freeze"
                             begin="bounce4.end" end="dummyRect.mouseout" dur="0.05s" id="bounce5"/>

                    <animate href="#p" attributeName="d" to="M0,200 Q80,100 400,200 V150 H0 V50" fill="freeze"
                             begin="dummyRect.mouseout" dur="0.15s" id="bounceOut"/>
                </svg>
                <div className="info">
                    <Typography color='white' sx={{fontSize: 20, fontFamily: font, fontWeight:10}}>
                        Carter Jones
                    </Typography>
                    <Typography color='white' sx={{fontSize: 20, fontFamily: font, fontWeight:10}}>
                        Partner
                    </Typography>
                </div>
            </div>
            <div className="card-blur"></div>
            </div>
        </>
    )
}
export default CustomCard;