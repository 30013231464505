import {
    Box,
    Button,
    createStyles,
    Divider,
    Drawer,
    Link,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar,
    Typography
} from "@mui/material";
import {IconContext} from "react-icons";
import React, {createRef, useCallback, useEffect, useState} from "react";

function SideMenu (props) {
    const logo = require("../assets/img/sample-logo.png").default;
    const drawerWidth = 260;
    const font = ["Montserrat", "Helvetica Neue", 'Arial', 'sans-serif'].join(',');
    const prim = 'rgba(47, 79, 79,0.92)';
    const second = 'rgba(255, 255, 255,0.9)';
    const hover = 'rgba(240, 240, 255,0.4)';
    const [active, setActive] = React.useState(props.routes[0]);

    const handleComponent = (event, val) => {
        if(val) {
            setActive(active => val);
        }
    }

    useEffect(() => {
        props.parentCallback(active.component);
    })

    return (
        <Drawer
            sx={{
                elevation:8,
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: prim
                },
            }}
            variant="permanent"
            anchor="left"
            PaperProps={{ elevation: 9 }}

        >
            <Toolbar>
                <Link href="/home" target="_blank" underline="none" sx={{display:'flex', flexGrow:1 , color:'white', '&:hover': {textDecoration: 'none', color:'white'}}}>
                    <img src={logo} style={{width:30,height:35, marginBottom:8 }}/>
                    <Typography variant="h6" color='white' sx={{pt:.5,ml:1.5, fontSize: 20, fontFamily: font}}>
                        JDC
                    </Typography>
                </Link>
            </Toolbar>

            <Box sx={{ mx:2}}>
                <Divider sx={{width:'100%', color:'white', borderColor:'rgba(255, 255, 255, 0.3)'}}/>
            </Box>
            <Box sx={{pt:2, mx:2, width:'260px'}}>
                <ToggleButtonGroup
                    value={active}
                    exclusive
                    fullWidth
                    orientation={'vertical'}
                    onChange={handleComponent}
                >
                { props.routes.map((prop) => {
                return (
                    <ToggleButton
                        value={prop}
                        sx={{
                            my: .2,
                            py: 1.1,
                            borderRadius: '25px',
                            width: '85%',
                            '&:hover': {backgroundColor: hover, color: prim}
                        }}

                    >
                        <IconContext.Provider value={{float: 'left', size: '20'}}>
                            <div>
                                {prop.icon}
                            </div>
                        </IconContext.Provider>
                        <Typography component="a"
                                    sx={{ml: 1, textDecoration: 'none', pl: 1, fontFamily: font}}>{prop.name}</Typography>
                    </ToggleButton>
                )
            })}
            </ToggleButtonGroup>
            </Box>
        </Drawer>
    );
}

export default SideMenu;