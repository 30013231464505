import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import routes from "./routes.js";
import {AppBar, Box, CssBaseline, Toolbar} from "@mui/material";
import SideMenu from "../../components/SideMenu";

function Dashboard(props) {
    const [backgroundColor, setBackgroundColor] = React.useState("blue");
    const drawerWidth = '260px';

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
            >
            </AppBar>
            <Box
                component="main"
                sx={{ flexGrow: 1, backgroundColor: 'lightblue', p: 3 }}
            >
                <Toolbar />
                <Switch>
                    {routes.map((prop, key) => {
                        return (
                            <Route
                                path={prop.layout + prop.path}
                                component={prop.component}
                                key={key}
                            />
                        );
                    })}
                    <Redirect from="/admin" to="/admin/manage" />
                </Switch>
            </Box>
        </Box>
    );
}
export default Dashboard;