import Manage from "./Manage";
import Overview from "./Overview";

import React from "react";
import {GiChart} from "react-icons/all";

var adminRoutes = [
  {
    path: "/overview",
    name: "Overview",
    icon: <GiChart/>,
    component: <Overview/>,
    layout: "/admin",
    isActive: false
  },
  {
    path: "/manage",
    name: "Manage",
    icon:  <GiChart/>,
    component: <Manage/>,
    layout: "/admin",
    isActive: false
  },
  // {
  //   path: "/messages",
  //   name: "Messages",
  //   icon:  <GiChart/>,
  //   component: <Messages/>,
  //   layout: "/admin",
  //   isActive: false
  // },
  // {
  //   path: "/Preferences",
  //   name: "Preferences",
  //   icon:  <GiChart/>,
  //   component: <Preferences/>,
  //   layout: "/admin",
  //   isActive: false
  // },
];
export default adminRoutes;
