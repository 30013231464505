import Portfolio from "./Portfolio.js";


var dashRoutes = [
  {
    path: "/portfolio",
    name: "Portfolio",
    icon: "design_app",
    component: Portfolio,
    layout: "/dashboard",
  },
  // {
  //   path: "/messages",
  //   name: "Messages",
  //   icon: "ui-1_email-85",
  //   component: Messages,
  //   layout: "/dashboard",
  // },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   icon: "ui-1_email-85",
  //   component: Preferences,
  //   layout: "/dashboard",
  // }
];
export default dashRoutes;
