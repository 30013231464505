import React, {createRef, useEffect, useState} from "react";
import {Box, Button, Grid, IconButton, Typography} from "@mui/material";
// import '../assets/css/buttonpulse.css'
import "../pages/Landing/Landing.css";
import { fadeIn } from 'react-animations'
import styled from "styled-components";
import { useInView } from 'react-intersection-observer'
import Team from "../pages/Landing/Team";
import {BsChevronCompactDown, IoIosNotificationsOutline} from "react-icons/all";
import {IconContext} from "react-icons";
import '../index.css';



function IndexHeader () {

    const image = "url(" + require("../assets/img/mountains.jpg").default + ")";
    const font = ["Montserrat", "Helvetica Neue", 'Arial', 'sans-serif'].join(',');
    const primary = 'rgba(47, 79, 79,0.92)';
    const [fade,setFade] = useState(true);

    // const fadeAnimation = keyframes`${fadeIn}`;
    //
    // const BouncyDiv = styled.div`animation: 1s ${fadeAnimation};`;



    return (
        <>
               <Grid
                   container
                   direction="column"
                   justifyContent="center"
                   alignItems="center"
                   sx={{height:'90vh'}}
               >
                   <Grid item md={6} sx={{mt:1}}>
                           <Box>
                               <div className={fade ? 'line1': ''}/>
                               <Grid container>
                                   <Grid item>
                                       <div className={fade ? 'height1': ''}/>
                                   </Grid>
                                   <Grid item sx={{pl:2,pr:2,pt:2}} >
                                       <img className='logo' style={{width: '40vw', marginBottom: 20}} src={require("../assets/img/Artboard 10.svg").default}
                                       ></img>
                                   </Grid>
                                   <Grid item>
                                       <div className={fade ? 'height2': ''}/>
                                   </Grid>
                                   <div className={fade ? 'line2': ''}/>
                               </Grid>
                           </Box>
                   </Grid>
                   <Grid item md={2}>
                       <Button
                           variant='contained'
                           href="/signup"
                           className='investbutton'
                           sx={{width:'15vw', height:'6vh', mt:'5vh', fontFamily:font, backgroundColor:"darkslategray",'&:hover': {background: primary, color: 'white'}}}
                       >
                           Invest
                       </Button>

                   </Grid>


                   {/*<Grid item sx={{mt:25}}>*/}
                   {/*    <Typography color='white' sx={{fontWeight: 500, fontSize: 20, fontFamily: this.font}}>*/}
                   {/*        Learn More*/}
                   {/*    </Typography>*/}
                   {/*</Grid>*/}
                   {/*<Grid item xs>*/}
                   {/*    <IconContext.Provider value={{ size:'30', color: "white"}}>*/}
                   {/*        <div>*/}
                   {/*            <BsChevronCompactDown/>*/}
                   {/*        </div>*/}
                   {/*    </IconContext.Provider>*/}
                   {/*</Grid>*/}
               </Grid>
            <div style={{height:'10vh', textAlign:'center'}}>
                <Typography color='white' sx={{fontSize: 18, fontFamily: font, fontWeight:10}}>
                    Learn More
                </Typography>
                <IconContext.Provider value={{color:'white'}}>
                    <IconButton href={'#team'}>
                        <BsChevronCompactDown size={20}/>
                    </IconButton>
                </IconContext.Provider>
            </div>
        </>
    );
}
export default IndexHeader;

