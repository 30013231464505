import React from "react";
import { Line, Bar } from "react-chartjs-2";
import PanelHeader from "./PanelHeader.js";

import {
    dashboardPanelChart,
} from "./charts.js";

function Portfolio() {
    return (
        <>
            <PanelHeader
                size="lg"
                content={
                    <Line
                        data={dashboardPanelChart.data}
                        options={dashboardPanelChart.options}
                    />
                }
            />
        </>
    );
}

export default Portfolio;
