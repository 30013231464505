// TODO: BORDER ISSUE ON CLICKING ICONS IS DUE TO MATERIAL UI / BOOTSTRAP CONFLICT

import React from "react";
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemIcon, Divider, Drawer, Link
} from "@mui/material";

import {IconContext} from "react-icons";
import {RiMenuLine} from "react-icons/all";
import {default as logo} from "../assets/img/sample-logo.png";

class IndexNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      navBarColor: 'transparent'
    }
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.primary = 'rgba(47, 79, 79,0.92)';
    this.font= ["Montserrat", "Helvetica Neue", 'Arial', 'sans-serif'].join(',');
  }

  componentDidMount() {
    window.addEventListener('scroll',(event) => {
          if (window.scrollY > 300) {
            this.setState({navBarColor: this.primary});
          } else {
            this.setState({navBarColor: 'transparent'});
          }
    });
  }

  toggleDrawer () {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  render() {
    const data = [
        // {
        //   name : 'Invest',
        //   href : '/signup',
        //
        // },
        // {
        //   name : 'About',
        //   href : '#about'
        // },
        // {
        //   name : 'The Team',
        //   href : '#team'
        // },
        {
          name : 'Home',
          href : '/'
        },
        {
          name : 'Login',
          href : '/login'
        },
        {
          name : 'Sign Up',
          href : '/signup'
        }
        ];

    const menu = (
        <Box
            sx={{ width: 200, backgroundColor:this.primary}}
            role="presentation"

        >
          <List sx={{alignItems:'flex-start', pl:0}}>
            {data.map((prop, key) => {
              return (
                  <>
                    <ListItem button component='a' href={prop.href} key={prop.name}>
                        <Typography align='left' sx={{textAlign:'left', color:'white', fontFamily: this.font}}>
                            {prop.name}
                        </Typography>
                    </ListItem>
                    <Divider/>
                  </>
              )})}
          </List>
            <Box sx={{bgcolor:'darkslategray', height:'100vh'}}></Box>
        </Box>
    )
    return (
        <Box>
          <AppBar sx={{background:this.state.navBarColor}} elevation={0}>
            <Toolbar>
                <Link href="/home"  underline="none" sx={{display:'flex', flexGrow:1 , color:'white', '&:hover': {textDecoration: 'none', color:'white'}}}>
                    <Typography variant="h6" color='white' sx={{fontSize: 20, fontFamily: this.font}}>
                    </Typography>
                </Link>
              <IconButton sx={{ mr: 2,  border:'none', outline: 'none' }}
                          onClick={this.toggleDrawer}
              >
                <IconContext.Provider value={{ size:'25', color: "white"}}>
                  <div>
                    <RiMenuLine/>
                  </div>
                </IconContext.Provider>
              </IconButton>
              <Drawer
                  anchor='right'
                  open={this.state.menuOpen}
                  onClose={this.toggleDrawer}
              >
                {menu}
              </Drawer>
            </Toolbar>
          </AppBar>
        </Box>
    )
  }
}

export default IndexNavbar;