import React, {useState} from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import adminRoutes from "./routes.js";

import {
    AppBar,
    Box, Button,
    CssBaseline, Grid, IconButton,
    Toolbar, Typography,
} from "@mui/material";

import SideMenu from "../../components/SideMenu";
import Overview from "./Overview";
import {IoAddSharp, IoIosNotificationsOutline, RiMessageLine} from "react-icons/all";
import {IconContext} from "react-icons";

function Admin(props) {
    const font = ["Montserrat", "Helvetica Neue", 'Arial', 'sans-serif'].join(',');
    const primaryColor = 'rgba(47, 79, 79,0.92)';
    const secondaryColor = 'rgba(255, 255, 255,0.9)';
    const hover = 'rgba(240, 240, 255,0.9)';

    const drawerWidth = 260;
    const [local, setLocal] = useState(<Overview/>);

    const handleCallback = (data) => {
        setLocal(local => data);
    }
    return (
        <Box sx={{ display: 'flex' }} >
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ width: `calc(100% - ${drawerWidth}px)`, height:'5vh', backgroundColor:'#FBFAF5'}}
            >
                <Box sx={{flexGrow:1}}>
                    <Box sx={{float:'left',color:'black'}}>
                    </Box>
                    <Box sx={{float:'right',color:'black'}}>
                        <IconContext.Provider value={{mt:2, size: 20, color: "black", fontWeight:10}}>
                            <IconButton>
                                <RiMessageLine/>
                            </IconButton>
                        </IconContext.Provider>
                        <IconContext.Provider value={{color:'black'}}>
                            <IconButton sx={{mr:2, '&:hover':{backgroundColor:'rgba(100,100,100,0.3)'}}}>
                                <IoIosNotificationsOutline size={20}/>
                            </IconButton>
                        </IconContext.Provider>

                    </Box>
                </Box>
            </AppBar>
            <SideMenu {...props} routes={adminRoutes} parentCallback={handleCallback} />
            {local}


        </Box>
    );
}
export default Admin;