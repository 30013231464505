import React, {useState} from "react";

// core components
import Footer from "../../components/Footer";

// sections for this page
import Team from "./Team.js";
import IndexNavbar from "../../components/IndexNavbar";
import IndexHeader from "../../components/IndexHeader";
import {Box, Grid, Paper, Stack} from "@mui/material";
import { styled } from '@mui/material/styles';

function Landing() {
    const [isVisible,setIsVisible] = useState(false);

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <div className="home-background" style={{position:'sticky'}}>
            <IndexNavbar/>
            <div id='home' className='gtfo' filter-color="none" style={{paddingBottom:0, marginBottom:0,position:'relative'}}>
                    <IndexHeader></IndexHeader>
            </div>
            <Team/>
        </div>
    );
}

export default Landing;
