import React from 'react';
import {Grid, LinearProgress, Paper, Stack, Typography} from "@mui/material";

function Overview () {
    return (
        <>
            <div style={{width:'calc(100% - 24px)', float:'right'}}>
                <Grid container spacing={1}>
                    <Grid container item spacing={5}>
                        <Grid item component={Paper} xs={3} elevation={8} sx={{ backgroundColor: 'gray', height:'30vh', mx:1}}>
                            <Typography sx={{fontSize: 25, color: 'white'}}>
                                Left
                            </Typography>
                            <Stack sx={{ mt:'auto', mb:0, width: '100%', color: 'grey.500', float:'left' }} spacing={2}>
                                <LinearProgress color="secondary" />
                                <LinearProgress color="success" />
                            </Stack>
                        </Grid>
                        <Grid item xs={3} component={Paper} elevation={3} sx={{ backgroundColor: 'gray', height:'30vh', mx:1}}>
                            <Typography sx={{fontSize: 25, color: 'white'}}>
                                Middle
                            </Typography>
                        </Grid>
                        <Grid item xs={3} component={Paper} elevation={3} sx={{ backgroundColor: 'gray', height:'30vh', mx:1}}>
                            <Typography sx={{fontSize: 25, color: 'white'}}>
                                Right
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
export default Overview;