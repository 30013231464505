import React, {memo, useEffect, useState} from 'react';
import {
    AppBar, Autocomplete,
    Box, Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl,
    Grid,
    IconButton,
    Input, InputAdornment,
    InputLabel, OutlinedInput,
    Paper, Stack,
    Tab,
    TableRow,
    Tabs,
    TextField,
    Toolbar,
    Typography,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Investors from "./Investors";
import Footer from "../../components/Footer";
import {a11yProps, TabPanel} from "./TabPanel";
import SwipeableViews from "react-swipeable-views";

import {GiPadlock, IoAddSharp, IoIosAddCircleOutline, RiMessageLine} from "react-icons/all";
import {IconContext} from "react-icons";
import Fire from "../../server/firebase";
import Button from "@mui/material/Button";

function Manage(props) {
    const [tab, setTab] = React.useState(0);
    const [dialog, setDialog] = React.useState(false);
    const [user, setUser] = useState(null);
    const [crypto, setCrypto] = useState([]);
    const [invest, setInvest] = useState(0);
    const [equity, setEquity] = useState(0);
    const [stocks, setStocks] = useState([]);

    const fire = new Fire();

    // useEffect(async () => {
    //     fetch("https://investing-cryptocurrency-markets.p.rapidapi.com/coins/list?edition_currency_id=12&time_utc_offset=28800&lang_ID=1&sort=PERC1D_DN&page=1", {
    //         "method": "GET",
    //         "headers": {
    //             "x-rapidapi-host": "investing-cryptocurrency-markets.p.rapidapi.com",
    //             "x-rapidapi-key": "abbf0be346msh7a4e0be359c96c5p119402jsna029e13c6f1b"
    //         }
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             let s = '';
    //            data.data[0].screen_data.crypto_data.forEach((val)=> s += (val.name));
    //            console.log(s);
    //         })
    //         .catch(err => {
    //             console.error(err);
    //         });
    // },[]);


    const font = ["Montserrat", "Helvetica Neue", 'Arial', 'sans-serif'].join(',');
    const primaryColor = 'rgba(47, 79, 79,0.92)';
    const secondaryColor = 'rgba(255, 255, 255,0.9)';
    const hover = 'rgba(240, 240, 255,0.9)';
    const theme = useTheme();
    const toolbarColor = 'rgb(27, 35, 48)';

    const handleChangeTab = (event, tab) => {
        setTab(tab);
    };

    function handleEntry() {
        setDialog(!dialog);
    }
    function testHandle(data) {
        setUser(data);
    }

    function handleStocks(event) {
        setStocks(event.target.value);
    }
    function handleEquity(event) {
        setEquity(event.target.value);
    }
    function handleInvest(event) {
        setInvest(event.target.value);
    }

    function handleSubmit(event) {

        if(stocks && equity && invest && user) {

            fire.writeHolding(stocks,equity,invest,user);
        }
    }
    const [state, setState] = useState({
        invest: 0,
        equity: 0,
        stocks: [],
    })

    const EntryDialog = () => {
        return (
                <Dialog open={dialog} onClose={handleEntry}>
                    <DialogTitle>Data Entry</DialogTitle>
                    <DialogContent sx={{width:'500px',height:'350px'}}>
                        <Stack spacing={2}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Amount"
                                />
                            </FormControl>
                            <TextField
                                id="standard-number"
                                label="Equity"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            />
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={crypto}
                                freeSolo
                                getOptionLabel={(option) => option.title}
                                filterSelectedOptions
                                onChange={() => handleStocks}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={'standard'}
                                        label="Stock Holdings"
                                        placeholder="Enter Value"
                                    />
                                )}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleEntry}>Cancel</Button>
                        <Button onClick={handleSubmit}>Subscribe</Button>
                    </DialogActions>
                </Dialog>
        )
    }
    return (
        <Box sx={{flexGrow:1, height:'100%'}}>
            <Grid container  spacing={.05} sx={{mt:4}}>
                <Grid item container md={8}>
                    <Toolbar sx={{display:'flex', height:'150px', bgcolor:toolbarColor,width:'100%'}}>
                        <Typography color='white' sx={{mt:9, fontSize: 32, fontFamily: font, fontWeight:'40px'}}>
                            Manage |
                        </Typography>
                        <Typography color='white' sx={{ml:1, mt:9.5, fontSize: 20, fontFamily: font, fontWeight:10}}>
                           {tab == 0 ? 'Investors' : 'Pending'}
                        </Typography>
                    </Toolbar>
                    <Paper sx={{overflow:'hidden'}}>
                        <AppBar position={'sticky'}>
                            <Tabs
                                value={tab}
                                onChange={handleChangeTab}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label="Investors" {...a11yProps(0)} />
                                <Tab label="Pending" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <SwipeableViews index={tab}>
                            <Box component={Box} style={{padding:0}} value={tab} index={0}>
                                <Investors callback={testHandle} tab={0}/>
                            </Box>
                            <Box style={{padding:0}} value={tab} index={1}>
                                <Investors callback={testHandle} tab={1}/>
                            </Box>
                        </SwipeableViews>
                    </Paper>
                </Grid>
                <Grid item container md={4}>
                    <Grid container item md={12} >
                        <Toolbar sx={{display:'block', bgcolor:toolbarColor,width:'100%',height:'150px'}}>
                            <Grid item container sx={{width:'100%'}}>
                                <Grid item>
                                    <Box sx={{float:'left'}}>
                                        <IconContext.Provider value={{color:'white'}}>
                                            <IconButton onClick={handleEntry} sx={{mt:2,backgroundColor:'blue', '&:hover':{backgroundColor:'rgba(0,0,255,0.7)'}}}>
                                                <IoAddSharp size={30}/>
                                            </IconButton>
                                        </IconContext.Provider>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Typography color='white' sx={{mt:7,fontSize: 20, fontFamily: font, fontWeight:10}}>
                                { (user) ? user[1].firstName + ' ' + user[1].lastName : ''}
                            </Typography>
                        </Toolbar>
                        <Grid container item md={12}>
                            <Grid item component={Paper} sx={{bgcolor:'rgba(255,0,0,0.05)'}} md={6}>
                            </Grid>
                            <Grid item component={Paper} sx={{bgcolor:'rgba(0,255,0,0.05)'}} md={6}>

                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item component={Paper} sx={{bgcolor:'rgba(0,0,255,0.05)'}} md={6}>

                            </Grid>
                            <Grid item component={Paper} sx={{bgcolor:'rgba(255,255,0,0.05)'}} md={6}>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container md={12}>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
            {dialog && <EntryDialog/>}
        </Box>
    )
}
export default Manage;